import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult7.jpg'
import dentalTreatment1Image from '../../assets/static/service/dentalTreatment1.png'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

/** 家庭牙科 */
const DentalTreatment = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.dentalTreatment' }),
      path: '/service/dentalTreatment'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.dentalTreatment' })}>
      <Seo
        title='洗牙看哪科？牙痛看哪科？健保特約家庭牙醫專科-日不落牙醫集團'
        metaDescription='如果不清楚牙齒為什麼不舒服？建議預約健保特約的家庭牙醫專科。日不落為提高看診的品質，台北、新竹、台中、台南皆引進『口內掃描儀』，僅需短短3分鐘，即可將您的齒模數位化。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.dentalTreatment' })}</StyledContentTitle>
      <ContentSection title='家人朋友都要看的家庭牙科'>
        <p>
          日不落牙醫集團除了專業的美齒專科服務 (隱形矯正、美學瓷貼片、牙齒美白等)，本院亦提供基礎的健保特約：洗牙、補牙等。建議大家養成半年定期回診洗牙的習慣，讓牙齒得到妥善治療。
        </p>
        <p>
          為提供您更好的看診體驗，特別引進丹麥<strong> 3 shape 口內掃描儀</strong>
          ，讓您遠離傳統印模噁心嘔吐感，運用科技的光學原理，只要短短 3 分鐘，快速將您的齒模數位化，幫您省略漫長的等待時間。
        </p>
      </ContentSection>
      <ContentSection title='困難智齒拔除'>
        <img src={dentalTreatment1Image} alt='「膠原蛋白」齒槽脊保存術' />
        <p>
          許多人在拔完智齒後，仍然有強烈的不適感，除了醫師所開立的處方，<strong>「膠原蛋白」</strong>
          齒槽脊保存術能舒緩拔牙後的疼痛感及出血，還能有效加快牙肉的組織生長，讓後續矯正或植牙的處理更加順利。
        </p>
        <ListGroup>
          <ListItem content='止血效果：特殊可吸收生物材料，能立即止血，減少血塊脫落引發乾性齒槽炎之困擾。' />
          <ListItem content='減痛效果：加速傷口癒合，有效減輕疼痛。' />
          <ListItem content='美化效果：牙床齒槽脊高度得以維持，減少萎縮量。' />
          <ListItem content='衛生效果：保護傷口，避免異物進入引起細菌感染。' />
          <ListItem content='預防效果：健全牙周組織，促進骨頭生成，減少拔牙後病理性骨折的風險。' />
        </ListGroup>
      </ContentSection>
    </ServiceLayout>
  )
}

export default DentalTreatment
